import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useSelectedMarket } from 'store';
// Mock cryptocurrency data
const cryptocurrencies = [
    { id: 'snv', name: 'SNV-WETH', symbol: 'SNV' },
    { id: 'btc', name: 'BTC-USD', symbol: 'BTC' },
    // { id: 'ethereum', name: 'Ethereum', symbol: 'ETH' },
    // { id: 'ripple', name: 'Ripple', symbol: 'XRP' },
    // { id: 'cardano', name: 'Cardano', symbol: 'ADA' },
    // { id: 'dogecoin', name: 'Dogecoin', symbol: 'DOGE' },
    // { id: 'polkadot', name: 'Polkadot', symbol: 'DOT' },
    // { id: 'litecoin', name: 'Litecoin', symbol: 'LTC' },
    // { id: 'stellar', name: 'Stellar', symbol: 'XLM' },
    // { id: 'chainlink', name: 'Chainlink', symbol: 'LINK' },
    // { id: 'tether', name: 'Tether', symbol: 'USDT' }
];
interface SearchBarProps {
    onSelect?: (crypto: { id: string; name: string }) => void;
}
const SearchBar: React.FC<SearchBarProps> = ({ onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCrypto, setSelectedCrypto] = useState<{ id: string; name: string; symbol: string } | null>(
        null
    );
    const [isOpen, setIsOpen] = useState(false);
    const [filteredCryptos, setFilteredCryptos] = useState(cryptocurrencies);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { setSelectedMarket } = useSelectedMarket();
    useEffect(() => {
        const savedMarket = localStorage.getItem('selectedMarket');
        if (!savedMarket) {
            localStorage.setItem('selectedMarket', 'snv');
            setSelectedMarket('snv');
            const defaultCrypto = cryptocurrencies.find((crypto) => crypto.symbol === 'SNV');
            if (defaultCrypto) setSelectedCrypto(defaultCrypto);
        } else {
            const savedCrypto = cryptocurrencies.find((crypto) => crypto.symbol === savedMarket);
            if (savedCrypto) {
                setSelectedCrypto(savedCrypto);
            } else {
                localStorage.setItem('selectedMarket', 'snv');
                setSelectedMarket('snv');
                const defaultCrypto = cryptocurrencies.find((crypto) => crypto.symbol === 'SNV');
                if (defaultCrypto) setSelectedCrypto(defaultCrypto);
            }
        }
    }, []);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setIsOpen(true);
    };
    const handleCryptoSelect = (crypto: { id: string; name: string; symbol: string }) => {
        localStorage.setItem('selectedMarket', crypto.id);
        setSelectedMarket(crypto.id);
        setSelectedCrypto(crypto);
        setSearchTerm('');
        setIsOpen(false);
        if (onSelect) {
            onSelect(crypto);
        }
    };
    useEffect(() => {
        const filtered = cryptocurrencies.filter(
            (crypto) =>
                crypto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                crypto.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCryptos(filtered);
    }, [searchTerm]);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                setSearchTerm('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className="w-full relative" ref={dropdownRef}>
            <div className="relative">
                <input
                    type="search"
                    placeholder={
                        selectedCrypto ? `${selectedCrypto.name} (${selectedCrypto.symbol})` : 'Search market'
                    }
                    value={searchTerm}
                    onChange={handleInputChange}
                    onFocus={() => setIsOpen(true)}
                    className="w-full px-2 sm:px-4 py-2 text-sm sm:text-base text-gray-300 bg-[#262B33] bg-opacity-50 rounded-full focus:outline-none transition-all duration-300 ease-in-out placeholder-gray-500 backdrop-blur-sm pr-8 sm:pr-10"
                    aria-label="Search cryptocurrencies"
                    aria-expanded={isOpen}
                    aria-controls="crypto-dropdown"
                />
                <FaChevronDown className="absolute right-2 sm:right-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm sm:text-base" />
            </div>
            {isOpen && (
                <div
                    id="crypto-dropdown"
                    className="scrollBarDiv absolute z-10 w-full mt-2 bg-[#262B33] bg-opacity-95 backdrop-blur-sm rounded-md shadow-lg max-h-60 overflow-auto"
                    role="listbox"
                >
                    {filteredCryptos.length > 0 ? (
                        filteredCryptos.map((crypto) => (
                            <button
                                key={crypto.id}
                                onClick={() => handleCryptoSelect(crypto)}
                                className="w-full text-left px-2 sm:px-4 py-2 text-sm sm:text-base text-gray-300 hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition-colors duration-150 ease-in-out"
                                role="option"
                            >
                                {crypto.name} ({crypto.symbol})
                            </button>
                        ))
                    ) : (
                        <div className="px-2 sm:px-4 py-2 text-sm sm:text-base text-gray-400">
                            No cryptocurrencies found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default SearchBar;