/* eslint-disable */
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { useSelectedMarket } from 'store';
import { useRef, useEffect, useState } from 'react';

const useApolloClient = () => {
  const { selectedMarket } = useSelectedMarket();
  const [apolloClient, setApolloClient] = useState(null);

  useEffect(() => {
    // Dynamically determine the API URL based on the selected market
    let APIURL;
    if (selectedMarket === 'snv') {
      APIURL = 'https://api.studio.thegraph.com/query/80821/nftfn-internal/v0.0.1';
    } else if (selectedMarket === 'btc') {
      APIURL = 'https://api.studio.thegraph.com/query/80821/nftfn-btc-testnet/version/latest';
    } else {
      APIURL = 'https://default-api-url.com/query/fallback-endpoint';
    }

    // Create ApolloClient
    const client = new ApolloClient({
      link: new HttpLink({ uri: APIURL }),
      cache: new InMemoryCache(),
    });

    setApolloClient(client);
  }, [selectedMarket]);

  return apolloClient;
};

export default useApolloClient;
