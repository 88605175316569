import EthIcon from 'components/Icons/EthIcon';
import React, { useEffect, useState } from 'react';
import DollarIcon from 'components/Icons/DollarIcon';
import { useSelectedMarket } from 'store';

interface LabelWethIconProps {
    height: number;
}

const LabelWethIcon = ({ height }: LabelWethIconProps) => {
    const [width, setWidth] = useState(7);
    const { selectedMarket } = useSelectedMarket();
    useEffect(() => {
        setWidth((height * 3) / 5);
    }, [height]);

    // return (
    //     <span className="pl-[6px] flex items-center">
    //         <EthIcon height={height} width={width} />
    //     </span>
    // );
    const getIcon = () => {
        switch (selectedMarket.toLowerCase()) {
            case "snv":
                return <EthIcon height={height} width={width} />;
            case "btc":
                return <DollarIcon height={15} width={15} />;
            default:
                return null; // Fallback for unsupported markets
        }
    };

    return <span className="pl-[6px] flex items-center">{getIcon()}</span>;
};

export default LabelWethIcon;
