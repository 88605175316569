import { WALLETS,
    GET_LIQUIDATEE_COUNT,
    GET_NET_LIQUIDATION_EARNINGS,
    WALLETS_LEFT,
    GET_USER_TRADE_COUNT_VOLUME,
    GET_INITIAL_INVESTMENT,
    GET_MARKPRICE
 } from "./Queries";



import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { formatPerMarket } from 'utils/formatUnits';
import { shortFormat } from 'utils/shortFormat';
import { truncateToTwoDecimalPlaces } from 'utils/truncateValue';
import { useSelectedMarket } from 'store';

export function useWalletQuery() {
    const { loading, error, data } = useQuery(WALLETS);
    const [users, setUsers] = useState(0);
  
    useEffect(() => {
      if (!loading && !error && data) {
        setUsers(data?.protocolStats[0].totalUsers || 0);
      }
    }, [loading, error, data]);
    //console.log("users",users);
    return { loading, error, users };
  }


  export function useWalletLiquidateeCnt() {
    const { loading, error, data } = useQuery(GET_LIQUIDATEE_COUNT);
    
    const [liquidateeCnt, setLiquidateeCount] = useState(0);
  
    useEffect(() => {
      if (!loading && !error && data) {
        setLiquidateeCount(data.liquidations.length || 0);
      }
    }, [loading, error, data]);
    //console.log("users",users);
    return { loading, error, liquidateeCnt };
  }

  export function useWalletLiquidationEarnings() {
    const { loading, error, data } = useQuery(GET_NET_LIQUIDATION_EARNINGS);
    
    const [liquidationEarnings, setLiquidationEarnings] = useState('');
    const { selectedMarket } = useSelectedMarket();
    const volumePrecision = selectedMarket === 'btc' ? 8 : 18;
    const pricePrecision = selectedMarket === 'btc' ? 6 : 18;
    useEffect(() => {
      if (!loading && !error && data) {
        setLiquidationEarnings(formatPerMarket(data?.protocolStats[0]?.totalLiquidationFeeGenerated, volumePrecision).toString());
      }
    }, [loading, error, data]);
    //console.log("users",users);
    return { loading, error, liquidationEarnings };
  }
  
  export function useWalletsLeft() {
    const { loading, error, data } = useQuery(WALLETS_LEFT);
    
    const [walletsLeft, setWalletsLeft] = useState(0);
  
    useEffect(() => {
      if (!loading && !error && data) {
        setWalletsLeft(data.positions.length || 0);
      }
    }, [loading, error, data]);
    //console.log("users",users);
    return { loading, error, walletsLeft };
  }

  export function useTraderDetails() {
    const ALL_TIME_TIMESTAMP = "43084000";

    const [dataList, setDataList] = useState<Array<any>>([]);
    const [skip, setSkip] = useState(0);
    const [first, setFirst] = useState(1000);
    const [count, setCount] = useState("0");
  
    const { loading, error, data, fetchMore } = useQuery(GET_USER_TRADE_COUNT_VOLUME, {
      variables: { timestamp: ALL_TIME_TIMESTAMP, skip: skip, first: first, count: count },
      notifyOnNetworkStatusChange: true,
    });
    const { loading:loadinginitialinvest, data:initialinvestment} = useQuery(GET_INITIAL_INVESTMENT, {
        notifyOnNetworkStatusChange: true,
    });
    const { loading:loadingmp, data:markprice} = useQuery(GET_MARKPRICE, {
        notifyOnNetworkStatusChange: true,
    });
    //console.log("data",data);
  
    useEffect(() => {
      if (!loading) {
        if (data?.userAccounts?.length > 0) 
        {            
          setDataList(prevDataList =>  {
            if (prevDataList) {
              return [...prevDataList, ...data.userAccounts];
            } else {
              return data.userAccounts;
            }});
          if (skip < 5000) {
            setSkip(skip + 1000);
            setFirst(1000);
          } else {
            setSkip(0);
            setFirst(1000);
            setCount((parseInt(count) + 5000).toString());
          }
          //console.log("first and skip",first,skip);
          fetchMore({
            variables: {
              skip: skip,
              count: count,
            },
          });
        }
      }
    }, [loading, data, fetchMore]);
    //console.log("dataList data",dataList);
    //const markprice:any = getMarkPrice();
    //const initialinvestment:any = getInitialInvestment();
    let totalIntialInvestment:any;    
  
    if (Array.isArray(initialinvestment?.userDeposits) && initialinvestment?.userDeposits.length > 0) {
        totalIntialInvestment = initialinvestment?.userDeposits.reduce((total:any, deposit:any) => {
        const amount = Number(deposit.amount);
        return total + amount;
      }      
      , 0);
      //console.log("totalIntialInvestment ",totalIntialInvestment);
    }
    const { selectedMarket } = useSelectedMarket();
    const ldata = dataList?.map((account:any,index)=>{
        //console.log("account is",account);
      let longVolume:any = BigInt(0);
      let shortVolume:any = BigInt(0);
      let unrealizedPnL:any = 0;
      let realizedPnLVal:any = 0;
      let totalPnL:any = 0;
      
      const volumePrecision = selectedMarket === 'btc' ? 8 : 18;
      const pricePrecision = selectedMarket === 'btc' ? 6 : 18;

      account?.totalPositions?.forEach((position:any) => {
        const i:any = 0;
        const posside = (position?.side === 'Long')?1:-1;
        //const amount = BigInt(position.amount);
        //console.log("amount",formatUnits(position.amount));
        if (position.side === 'Long') {
          longVolume += formatPerMarket(position.amount, volumePrecision);
          //console.log("longVolume",longVolume);
        } else if (position.side === 'Short') {
          shortVolume += formatPerMarket(position.amount, volumePrecision);
          //console.log("shortVolume",shortVolume);
        } else {
          return;
        }
        const posAmount:any = formatPerMarket(position?.amount, volumePrecision);
        const posAvgBuying:any = formatPerMarket(position?.averageBuyingPrice, pricePrecision);
        const markPriceVal = +formatPerMarket(markprice?.markPrices[0].MarkPrice, pricePrecision);
        const result = posside * posAmount * (markPriceVal - posAvgBuying);
        //unrealizedPnL += isNaN(result)?0:result;
        unrealizedPnL +=  (markprice?.markPrices[0]?.MarkPrice === 0) ?0.0 :result
  
        realizedPnLVal += Number(formatPerMarket(position.realizedPnl, volumePrecision ));
        
        totalPnL = realizedPnLVal + unrealizedPnL;
        //console.log("unrealizedPnL realizedPnLVal totalPnL",unrealizedPnL, realizedPnLVal,totalPnL);
      });
      const roiVal = (realizedPnLVal+unrealizedPnL)/totalIntialInvestment;
      
        return {
            id:index+1,
            useraddr:account.address,//.slice(0, 5) + "..." +account.address.slice(-4),
            price:!realizedPnLVal ? ('0.00') : (shortFormat(realizedPnLVal) ),
            fee:shortFormat(formatPerMarket((account?.cumulativeFees), volumePrecision)),
            deposit:shortFormat(formatPerMarket(account?.escrowedAmount, volumePrecision)),
            trades:account?.takerOrders?.length,
            unrealizedpnl:shortFormat(unrealizedPnL),
            roi:(isNaN(roiVal)?0.0:shortFormat(Number(roiVal))),
            tradelongvol:shortFormat(Number(truncateToTwoDecimalPlaces(longVolume))),//userposition.amount,
            tradeshortvol:shortFormat(Number(truncateToTwoDecimalPlaces(shortVolume))),
            timestamp:account?.createdAt,
            totalOpenPositions:account?.totalPositions.length,
            pnlVal: totalPnL
          }      
  
    })

    return { ldata };
}
  