import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectedMarket } from 'store';

const useManageRoute = () => {
    const { pathname: currentRoutePath } = useLocation();
    const { selectedMarket } = useSelectedMarket();
    const navigate = useNavigate();

    const isDashboardPage = () => {        
        return (
            currentRoutePath === `/dashboard/${selectedMarket}/` ||
            currentRoutePath === `/dashboard/${selectedMarket}/trades/` ||
            currentRoutePath === `/dashboard/${selectedMarket}/protocol/` ||
            currentRoutePath === `/dashboard/${selectedMarket}/marketing/}` ||
            currentRoutePath === `/dashboard/${selectedMarket}/users/}` ||
            currentRoutePath === `/dashboard/${selectedMarket}/rewards/}` 
        );
    };

    // const getTradeRoute = () => {
    //     return currentRoutePath;
    // };

    // const navigateToTradePage = () => {
    //     navigate(`/trade/${selectedMarket}`, { replace: true });
    // };

    // const isPortfolioPage = () => {
    //     return (
    //         currentRoutePath === `/portfolio/${selectedMarket}/assets` ||
    //         currentRoutePath === `/portfolio/${selectedMarket}/position` ||
    //         currentRoutePath === `/portfolio/${selectedMarket}/history` ||
    //         currentRoutePath === `/portfolio/${selectedMarket}/rewards`
    //     );
    // };

    const getDashboardPage = () => {
        return currentRoutePath;
    };

    const navigateToDashboardPage = () => {
        navigate(`/dashboard/${selectedMarket}`, { replace: true });
    };

    return {
        isDashboardPage,
        getDashboardPage,
        navigateToDashboardPage,
    };
};

export default useManageRoute;
