 import { shortFormat, useWindowSize } from 'utils';

import { useEffect, useState, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

// third-party

import { PiArrowElbowUpRightLight, PiArrowElbowDownRightLight, PiArrowRightLight } from "react-icons/pi";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

import { useTraderDetails,
         useWalletLiquidationEarnings,
         useWalletsLeft } from "./UsersApi";
import { getTokenLabel } from 'config/marketMapping';
import { useSelectedMarket } from 'store';

const Details = () => {
    const [realizedPUsersCnt, setRealizedPUsersCnt] = useState(0);
    const [usersDepositCnt, setUsersDepositsCount] = useState(0);
    const [realizedLUsersCnt, setRealizedLUsersCnt] = useState(0);
    const [unrealizedPUsersCnt, setUnRealizedPUsersCnt] = useState(0);
    const [unrealizedLUsersCnt, setUnRealizedLUsersCnt] = useState(0);
    const [totalOpenPos, setTotalOpenPositions] = useState(0);
    const [profitCnt, setUsersProfitCnt] = useState(0);
    const [lossCnt, setUsersLossCnt] = useState(0);
    
    const { ldata } = useTraderDetails();
    const { liquidationEarnings } = useWalletLiquidationEarnings();
    const { walletsLeft } = useWalletsLeft();
    const { selectedMarket } = useSelectedMarket();
    const tokenlabel = getTokenLabel(selectedMarket);

    //console.log("ldata",ldata);
    useEffect(() => {
        let realizedPUsersCnt = 0;
        let realizedLUsersCnt = 0;
        let unrealizedPUsersCnt = 0;
        let unrealizedLUsersCnt = 0;
        let userOpenedPosCnt = 0;
        let usersDepositCnt = 0;
        let profitCnt = 0;
        let lossCnt = 0;
        ldata?.map((item: any) => {
            if(item.pnlVal > 0) {
                setUsersProfitCnt(profitCnt++);
            }
            if(item.pnlVal < 0) {
                setUsersLossCnt(lossCnt++);
            }
            if(item.price > 0) {
                setRealizedPUsersCnt(realizedPUsersCnt++);
            }
            if (item.price < 0) {
                setRealizedLUsersCnt(realizedLUsersCnt++);
            }
            if(item.unrealizedpnl > 0) {
                setUnRealizedPUsersCnt(unrealizedPUsersCnt++);
            } 
            if(item.unrealizedpnl < 0) {
                setUnRealizedLUsersCnt(unrealizedLUsersCnt++);
            } 
            if(item.deposit > 0) {
                setUsersDepositsCount(usersDepositCnt++);
            }
            //console.log("TotalOpen Positions",item.totalOpenPositions);
            if(item.totalOpenPositions > 0) {
                setTotalOpenPositions(userOpenedPosCnt++);
            }        
        })
    })
    //console.log("map",ldata.length, totalOpenPos, realizedPUsersCnt,realizedLUsersCnt, unrealizedPUsersCnt, unrealizedLUsersCnt)
    //console.log("usersDepositCnt",usersDepositCnt);
    //const { realizedLosses } = useWalletsRealizedLosses();
    
    // TBD - The subgraph data is not correct, need to update the same
    //const { liquidateeCnt } = useWalletLiquidateeCnt();
     return (  
        <div id="chart">
            <div className="flex justify-start items-center gap-10 text-[20px] text-[#BDB4F5] cursor-pointer font-inter">            
            User Flow
            </div>  
            <div className="w-[100%] max-w-[1679px] p-6 pl-50 border rounded-[8px] pb-4 mt-2">
                <div className="flex justify-between items-center py-[15px] px-[2rem] max-w-[1679px] mx-auto ">                    
                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#10141B] text-[14px] rounded-[10px] text-[#E8E8E8]">
                        <div className="text-white text-[20px] font-bold mb-[6px]">
                            {usersDepositCnt}
                        </div>
                        #Users Deposited
                    </div>
                    <span>
                        <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                    </span>
                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#10141B] text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                        <div className="text-white text-[20px] font-bold mb-[6px]">
                            {totalOpenPos}
                        </div>
                        #Users Opened Trade
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="flex flex-row items-center">
                            <div className="flex items-center mt-[2px]">
                                <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#6004BB] font-inter text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                <div className="text-white text-[20px] font-inter mb-[6px]">
                                    {"TBD"}
                                </div>
                                #Users Liquidated    
                                </div>
                            </div>
                            <div className="flex items-center mb-1">
                                <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                <div className="ml-4">
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#6004BB] font-inter text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        {liquidationEarnings + " " + tokenlabel}
                                        </div>
                                        Liquidation earnings
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="flex items-center mt-[10px]">
                                <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#CE2EAE] text-[14px] font-inter rounded-[10px] text-[#E8E8E8] ml-4" style={{ whiteSpace: 'nowrap' }}>
                                <div className="text-white text-[20px] font-bold mb-[6px]">
                                    {profitCnt}
                                </div>
                                #Users in Profit
                                </div>
                            </div>
                            <div className="flex flex-col items-center mt-[10px]">
                                <div className="flex items-center mb-4">
                                    <PiArrowElbowUpRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>                        
                                    <div className="ml-4">
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#CE2EAE] font-inter text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        {realizedPUsersCnt}
                                        </div>
                                        #Realized Open Positions
                                    </div>
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <PiArrowElbowDownRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                    <div className="ml-4">
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#CE2EAE] font-inter text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        {unrealizedPUsersCnt}
                                        </div>
                                        #UnRealized Open Positions
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="flex items-center mt-[10px]">
                                <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#9619B4] text-[14px] font-inter rounded-[10px] text-[#E8E8E8] ml-4" style={{ whiteSpace: 'nowrap' }}>
                                <div className="text-white text-[20px] font-bold mb-[6px]">
                                    {lossCnt}
                                </div>
                                #Users in Loss
                                </div>
                            </div>
                            <div className="flex flex-col items-center mt-[10px]">
                                <div className="flex items-center mb-4">
                                    <PiArrowElbowUpRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>                        
                                    <div className="ml-4">
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#9619B4] text-[14px] font-inter rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        {realizedLUsersCnt}
                                        </div>
                                        #Realized Open Positions
                                    </div>
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <PiArrowElbowDownRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                    <div className="ml-4">
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#9619B4] text-[14px] font-inter rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        {unrealizedLUsersCnt}
                                        </div>
                                        #UnRealized Open Positions
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="flex items-center">
                                <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#FFFFFF66'}}/>
                                <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#10141B] text-[14px] font-inter rounded-[10px] text-[#E8E8E8] ml-4" style={{ whiteSpace: 'nowrap' }}>
                                <div className="text-white text-[20px] font-bold mb-[6px]">
                                    {walletsLeft}
                                </div>
                                #Users left
                                </div>
                            </div>
                            <div className="flex items-center mb-1">
                                <PiArrowRightLight style={{ height: '50px', width: '100px' , color: '#000000'}}/>
                                <div className="ml-4">
                                    <div className="p-6 pt-[8px] w-[231px] h-[76px] bg-[#000000] text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>                    
                </div>
            </div>
        </div>
        
        
    );
};
const DashboardUsersPage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                    <div className="flex  w-full gap-y-2">
                        <Details />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {/* <LeaderboardPage />                     */}
                </div>
            )}
        </div>
    );
};

export default DashboardUsersPage;
