import React from 'react';
import { useSelectedMarket } from 'store';

const LabelSnvIcon = () => {
    const { selectedMarket } = useSelectedMarket();
    return (
        <span className="text-[10px] font-normal font-inter h-[16px] bg-[#1C212C] text-[#E9E9E9] px-[8.85px] py-[1px] rounded-sm">
            {selectedMarket.toUpperCase()}
        </span>
    );
};

export default LabelSnvIcon;
