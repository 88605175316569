import { formatUnits } from 'viem';

export const formatPerMarket = (value: string | undefined | null, decimals: number): string => {
    try {
        if (!value || isNaN(Number(value)) || (Number(value) == 0)) {
            // Handle undefined, null, or invalid values gracefully
            return '0';
        }
        // Convert to BigInt safely after ensuring the value is a valid number
        return formatUnits(BigInt(Math.floor(Number(value))), decimals);
    } catch (err) {
        console.error('Error in formatUnits:', err);
        return '0'; // Fallback value in case of unexpected errors
    }
};

export default formatUnits;
