import LeaderboardTablePage from './LeaderboardTablePage';
import { ButtonInterface } from 'interfaces/DatePage';
import { formatPerMarket } from 'utils/formatUnits';
import { shortFormat } from 'utils/shortFormat';
import { truncateToTwoDecimalPlaces } from 'utils/truncateValue';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSelectedMarket } from 'store';

const GET_USER_TRADE_COUNT_VOLUME = gql`
  query GetUserTradeCountVolume($timestamp: String, $first: Int!, $skip: Int!, $count: String) {
    userAccounts (where: {createdAt_gte: $timestamp} 
      first: $first
      skip: $skip) {
      id
      address
      cumulativeFees
      escrowedAmount
      createdAt
      takerOrders (where: {timestamp_gt: $timestamp} 
        first: $first) {
        id
      }
      makerOrders(where: {status_not: Cancelled}) {
        id          
      }
      totalPositions (where: {status: Open, startTimestamp_gt: $timestamp}) {
        amount
        realizedPnl
        averageBuyingPrice
        side
      }
    }          
  }
`;
const GET_INITIAL_INVESTMENT = gql `query {
  userDeposits(first: 3, orderBy: user__createdAt, orderDirection: asc) {
    amount
  }
}`

const GET_MARKPRICE = gql`query {
  markPrices(orderBy: id, orderDirection: desc, first: 1) {
    id
    MarkPrice
  }
}`
const LeaderboardPage = () => {  
    const ALL_TIME_TIMESTAMP = "43084000";
    const [takerPoints, setMarketOrderPoints] = useState(0);
    const [makerPoints, setLimitOrderPoints] = useState(0);
    const [dataList, setDataList] = useState<Array<any>>([]);
    const [skip, setSkip] = useState(0);
    const [first, setFirst] = useState(1000);
    const [count, setCount] = useState("0");
    const { selectedMarket } = useSelectedMarket();
    const MAKER_ORDER_POINTS = 20;
    const TAKER_ORDER_POINTS = 10; 
    
    const { loading, error, data, fetchMore } = useQuery(GET_USER_TRADE_COUNT_VOLUME, {
      variables: { timestamp: ALL_TIME_TIMESTAMP, skip: skip, first: first, count: count },
      notifyOnNetworkStatusChange: true,
    });
    const { loading:loadinginitialinvest, data:initialinvestment} = useQuery(GET_INITIAL_INVESTMENT, {
      notifyOnNetworkStatusChange: true,
  });
  const { loading:loadingmp, data:markprice} = useQuery(GET_MARKPRICE, {
    notifyOnNetworkStatusChange: true,
  });
    console.log("data",data);
  
    useEffect(() => {
      if (!loading) {
        if (data?.userAccounts?.length > 0) 
        {            
          setDataList(prevDataList =>  {
            if (prevDataList) {
              return [...prevDataList, ...data.userAccounts];
            } else {
              return data.userAccounts;
            }});
          if (skip < 5000) {
            setSkip(skip + 1000);
            setFirst(1000);
          } else {
            setSkip(0);
            setFirst(1000);
            setCount((parseInt(count) + 5000).toString());
          }
          //console.log("first and skip",first,skip);
          fetchMore({
            variables: {
              skip: skip,
              count: count,
            },
          });
        }
      }
    }, [loading, data, fetchMore]);
    console.log("dataList data",dataList);

    let totalIntialInvestment:any;    
  
    if (Array.isArray(initialinvestment?.userDeposits) && initialinvestment?.userDeposits.length > 0) {
        totalIntialInvestment = initialinvestment?.userDeposits.reduce((total:any, deposit:any) => {
        const amount = Number(deposit.amount);
        return total + amount;
      }      
      , 0);
      //console.log("totalIntialInvestment ",totalIntialInvestment);
    }
    
    const ldata = dataList?.map((account:any,index)=>{
      const volumePrecision = selectedMarket === 'btc' ? 8 : 18;
      const pricePrecision = selectedMarket === 'btc' ? 6 : 18;
        //console.log("account is",account);
      let longVolume:any = BigInt(0);
      let shortVolume:any = BigInt(0);
      let unrealizedPnL:any = 0;
      let realizedPnLVal:any = 0;
      const makerTrades = (account?.makerOrders?.length || 0);
      const takerTrades = (account?.takerOrders?.length || 0);
      const limitRewards = (account?.makerOrders?.length || 0) * MAKER_ORDER_POINTS;
      const marketRewards = ((account?.takerOrders?.length || 0) * TAKER_ORDER_POINTS);
      const totalRewards:any = limitRewards + marketRewards; 
      account?.totalPositions?.forEach((position:any) => {
        const posside = (position?.side === 'Long')?1:-1;
        //const amount = BigInt(position.amount);
        //console.log("amount",formatUnits(position.amount));
        //if(selectedMarket === 'snv')
        if (position.side === 'Long') {
          longVolume += formatPerMarket(position.amount, volumePrecision);
          //console.log("longVolume",longVolume);
        } else if (position.side === 'Short') {
          shortVolume += formatPerMarket(position.amount, volumePrecision);
          //console.log("shortVolume",shortVolume);
        }
        const posAmount:any = formatPerMarket(position?.amount, volumePrecision);
        const posAvgBuying:any = formatPerMarket(position?.averageBuyingPrice, pricePrecision);
        const markPriceVal = +formatPerMarket(markprice?.markPrices[0].MarkPrice, pricePrecision);
        const result = posside * posAmount * (markPriceVal - posAvgBuying);
        //unrealizedPnL += isNaN(result)?0:result;
        unrealizedPnL +=  (markprice?.markPrices[0]?.MarkPrice === 0) ?0.0 :result
  
        realizedPnLVal += Number(formatPerMarket(position.realizedPnl, pricePrecision));
        //console.log("unrealizedPnL",shortFormat(result));
      });
      const roiVal = (realizedPnLVal+unrealizedPnL)/totalIntialInvestment;
        return {
            id:index+1,
            points: totalRewards,
            useraddr:account.address,//.slice(0, 5) + "..." +account.address.slice(-4),
            price:!realizedPnLVal ? ('0.00') : (shortFormat(realizedPnLVal) ),
            fee:shortFormat(formatPerMarket((account?.cumulativeFees), volumePrecision)),
            deposit:shortFormat(formatPerMarket(account?.escrowedAmount, volumePrecision)),
            trades:makerTrades + takerTrades ,
            unrealizedpnl:shortFormat(unrealizedPnL),
            roi:(isNaN(roiVal)?0.0:shortFormat(Number(roiVal))),
            tradelongvol:shortFormat(Number(truncateToTwoDecimalPlaces(longVolume))),//userposition.amount,
            tradeshortvol:shortFormat(Number(truncateToTwoDecimalPlaces(shortVolume))),
            timestamp:account?.createdAt,
          }      
  
    })

    
    console.log("LeaderboardPage page",ldata);
    const preSetButtons: Array<ButtonInterface> = [
        {
          text: 'ALL',
          isActive: false
      },
        {
            text: '1D',
            isActive: false
        },
        {
            text: '7D',
            isActive: false
        },
        {
            text: '1M',
            isActive: false
        },
        {
            text: '3M',
            isActive: false
        },
        {
            text: '6M',
            isActive: false
        }
    ];
    return (        
        <LeaderboardTablePage
                                tableData={ldata}
                                loading={false}
                                preSetButtons={preSetButtons}
                            />
    );
};

export default LeaderboardPage;
