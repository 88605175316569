// import axios, { CreateAxiosDefaults } from 'axios';
// import { API_RESOURCES, getBaseApiUrl } from 'config/constants';
// import { prependForwardSlash } from 'utils';
// import { useSelectedMarket } from 'store';

// const baseAxiosConfig: CreateAxiosDefaults<any> = {
//     // timeout: 5000
// };

// const { selectedMarket } = useSelectedMarket();
// export const referralSystemApi = axios.create({
//     baseURL: `${prependForwardSlash(getBaseApiUrl(selectedMarket))}${prependForwardSlash(API_RESOURCES.REFERRAL)}`,
//     ...baseAxiosConfig
// });

import { useEffect, useRef } from 'react';
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { API_RESOURCES, getBaseApiUrl } from 'config/constants';
import { prependForwardSlash } from 'utils';
import { useSelectedMarket } from 'store';

const baseAxiosConfig: CreateAxiosDefaults<any> = {
    // timeout: 5000
};

export const useReferralSystemApi = (): AxiosInstance => {
    const { selectedMarket } = useSelectedMarket();
    const apiClient = useRef<AxiosInstance>(axios.create(baseAxiosConfig));

    useEffect(() => {
        const baseURL = `${prependForwardSlash(getBaseApiUrl(selectedMarket))}${prependForwardSlash(API_RESOURCES.REFERRAL)}`;
        apiClient.current = axios.create({
            baseURL,
            ...baseAxiosConfig
        });
    }, [selectedMarket]);

    return apiClient.current;
};
