import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RainbowProvider, CustomContextProvider } from './context';
import useApolloClient from 'graphql/ApolloClient';
import { ApolloProvider } from '@apollo/client';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    }
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWithApollo = () => {
    const apolloClient = useApolloClient();
  
    if (!apolloClient) {
      // Render a loader or nothing until the ApolloClient is initialized
      return <div>Loading...</div>;
    }
  
    return (
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    );
  };

root.render(
    <SnackbarProvider>
        <RainbowProvider>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CustomContextProvider>
                        <AppWithApollo />
                    </CustomContextProvider>
                </ThemeProvider>
            </BrowserRouter>
        </RainbowProvider>
    </SnackbarProvider>
);
